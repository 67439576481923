<template>
  <b-modal
    id="form-multicast"
    :visible="shallShowMulticastFormModal"
    :title="`${$t('Form')} ${$t('Multicast')}`"
    footer-class="d-flex justify-content-between"
    size="xl"
    centered
    no-close-on-backdrop
    @change="(val) => $emit('update:shall-show-multicast-form-modal', val)"
  >
    <!-- Modal Header -->
    <template #modal-header>
      <h5 class="modal-title">
        {{ `${$t(typeMulticastForm)} ${$t('Multicast')}` }}
      </h5>
      <div class="modal-actions">
        <feather-icon
          icon="MinusIcon"
          class="cursor-pointer"
          @click="(busy === false) ? $emit('update:shall-show-multicast-form-modal', false) : false"
        />
        <!-- <feather-icon
          icon="Maximize2Icon"
          class="ml-1 cursor-pointer"
        /> -->
        <feather-icon
          icon="XIcon"
          class="ml-1 cursor-pointer"
          @click="(busy === false) ? onDiscard() : false"
        />
      </div>
    </template>

    <!-- Modal Footer -->
    <template #modal-footer>
      <!-- Footer: Left Content -->
      <div>
        <b-button
          v-if="(typeMulticastForm !== 'View')"
          ref="submit"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          right
          :disabled="busy"
          @click="onSubmit"
        >
          {{ $t('Save Data') }}
        </b-button>
      </div>

      <!-- Footer: Right Content -->
      <div>
        <!-- Discard -->
        <feather-icon
          icon="XCircleIcon"
          size="17"
          class="ml-75 cursor-pointer"
          @click="(busy === false) ? onDiscard() : false"
        />
      </div>
    </template>

    <!-- Modal: Body -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <div>
        <b-form
          ref="refForm"
          class="position-relative"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <div>
            <b-card style="margin-bottom: 0.6rem;">
              <div>
                <b-row>
                  <b-col cols="6">

                    <b-row>
                      <!-- Field: Name -->
                      <b-col
                        cols="12"
                      >
                        <b-form-group
                          v-if="(typeMulticastForm === 'Add' || typeMulticastForm === 'Edit')"
                          :label="$t('Name')"
                          label-for="multicast-name"
                        >
                          <validation-provider
                            #default="validationContext"
                            :name="$t('Name')"
                            rules="required"
                          >
                            <b-form-input
                              id="multicast-name"
                              v-model="multicastData.name"
                              :state="getValidationState(validationContext)"
                              :disabled="busy"
                            />
                            <small class="text-danger">{{ validationContext.errors[0] }}</small>
                          </validation-provider>

                        </b-form-group>

                        <b-form-group
                          v-else
                          :label="$t('Name')"
                          label-for="multicast-name"
                        >
                          <span class="form-info-box">{{ multicastData.name }}</span>
                        </b-form-group>
                      </b-col>
                    </b-row>

                    <b-row>
                      <!-- Field: Audience -->
                      <b-col
                        cols="12"
                      >
                        <b-form-group
                          v-if="(typeMulticastForm === 'Add' || typeMulticastForm === 'Edit')"
                          :label="$t('Audience')"
                          label-for="multicast-audience"
                        >
                          <validation-provider
                            #default="validationContext"
                            :name="$t('Audience')"
                            rules="required"
                          >
                            <b-form-input
                              v-model="multicastData.audience_id"
                              style="display: none;"
                              :state="getValidationState(validationContext)"
                            />
                            <b-input-group class="m-0">
                              <b-input-group-prepend
                                is-text
                                style="cursor: pointer;"
                                @click="selectAudience"
                              >
                                <feather-icon
                                  icon="PlusIcon"
                                  size="12"
                                />
                              </b-input-group-prepend>
                              <b-form-input
                                id="multicast-audience"
                                ref="refAudience"
                                v-model="multicastData.audience_name"
                                :disabled="true"
                                style="background: #f8f8f8 !important;"
                              />
                            </b-input-group>
                            <small class="text-danger">{{ validationContext.errors[0] }}</small>
                          </validation-provider>

                        </b-form-group>

                        <b-form-group
                          v-else
                          :label="$t('Audience')"
                          label-for="multicast-audience"
                        >
                          <span class="form-info-box">{{ multicastData.audience_name }}</span>
                        </b-form-group>
                      </b-col>

                    </b-row>

                    <b-row>
                      <!-- Field: Schedule -->
                      <b-col
                        cols="6"
                      >
                        <b-form-group
                          v-if="(typeMulticastForm === 'Add' || typeMulticastForm === 'Edit')"
                          :label="$t('Schedule')"
                          label-for="multicast-schedule"
                        >
                          <validation-provider
                            #default="validationContext"
                            :name="$t('Schedule')"
                            rules="required"
                          >
                            <b-form-radio
                              v-model="multicastData.is_schedule"
                              name="multicast-schedule"
                              :value="true"
                              class="custom-control-success cursor-pointer"
                              inline
                            >
                              <span class="cursor-pointer">{{ $t('Yes') }}</span>
                            </b-form-radio>
                            <b-form-radio
                              v-model="multicastData.is_schedule"
                              name="multicast-schedule"
                              :value="false"
                              class="custom-control-info cursor-pointer"
                              inline
                            >
                              <span class="cursor-pointer">{{ $t('No') }}</span>
                            </b-form-radio>
                            <small class="text-danger">{{ validationContext.errors[0] }}</small>
                          </validation-provider>

                        </b-form-group>

                        <b-form-group
                          v-else
                          :label="$t('State')"
                          label-for="multicast-state"
                        >
                          <span class="form-info-box">
                            <b-badge
                              pill
                              :variant="`light-${stateVariant(multicastData.state)}`"
                              class="text-capitalize"
                            >
                              {{ $t(textFirstUpper(multicastData.state)) }}
                            </b-badge>
                          </span>
                        </b-form-group>
                      </b-col>
                    </b-row>

                    <b-row
                      v-if="multicastData.is_schedule === true"
                      style="margin-top: 0.2rem;"
                    >
                      <!-- Date -->
                      <b-col cols="6">
                        <b-form-group
                          label="วันที่"
                          label-for="schedule-date"
                        >
                          <flat-pickr
                            v-model="multicastData.schedule_date"
                            class="form-control"
                            :config="{
                              dateFormat: 'd F Y',
                              minDate: 'today',
                              allowInput: false
                            }"
                            placeholder="เลือกวันที่"
                          />
                        </b-form-group>
                      </b-col>
                      <!-- Time -->
                      <b-col cols="6">
                        <b-form-group
                          label="เวลา"
                          label-for="schedule-time"
                        >
                          <flat-pickr
                            v-model="multicastData.schedule_time"
                            class="form-control"
                            :config="{
                              enableTime: true,
                              noCalendar: true,
                              dateFormat: 'H:i',
                              allowInput: false,
                              time_24hr: true,
                              minuteIncrement: 10,
                            }"
                            placeholder="เลือกเวลา"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="6">
                    <!-- Select -->
                    <div>
                      <b-tabs>
                        <b-tab
                          v-for="(chtmsg, index) in chatmessageArray"
                          :key="index"
                          :title="`${$t('Message')} ${index+1}`"
                        >
                          <b-row>
                            <!-- Field: Chatmessage -->
                            <b-col
                              cols="12"
                            >

                              <b-form-group
                                v-if="(typeMulticastForm === 'Add' || typeMulticastForm === 'Edit')"
                                :label="`${$t('Chat Message')} ${index+1}`"
                                :label-for="`multicast-chatmessage${index}`"
                              >
                                <validation-provider
                                  #default="validationContext"
                                  :name="`${$t('Chat Message')} ${index+1}`"
                                  :rules="(index === 0)? 'required' : ''"
                                >
                                  <b-form-input
                                    v-model="chatmessageArray[index].chatmessage_id"
                                    style="display: none;"
                                    :state="getValidationState(validationContext)"
                                  />
                                  <b-input-group class="m-0">
                                    <b-input-group-prepend
                                      is-text
                                      style="cursor: pointer;"
                                      @click="selectChatmessage(index)"
                                    >
                                      <feather-icon
                                        icon="PlusIcon"
                                        size="12"
                                      />
                                    </b-input-group-prepend>
                                    <b-input-group-prepend
                                      v-if="chatmessageArray[index].chatmessage_name !== ''"
                                      is-text
                                      style="cursor: pointer;"
                                      @click="clearSelectChatmessage(index)"
                                    >
                                      <feather-icon
                                        icon="XIcon"
                                        size="12"
                                      />
                                    </b-input-group-prepend>
                                    <b-form-input
                                      :id="`multicast-chatmessage${index}`"
                                      v-model="chatmessageArray[index].chatmessage_name"
                                      :disabled="true"
                                      style="background: #f8f8f8 !important;"
                                    />
                                  </b-input-group>
                                  <small class="text-danger">{{ validationContext.errors[0] }}</small>
                                </validation-provider>

                              </b-form-group>

                              <b-form-group
                                v-else
                                :label="`${$t('Chat Message')} 1`"
                                label-for="multicast-chatmessage"
                              >
                                <span class="form-info-box">{{ chatmessageArray[index].chatmessage_name }}</span>
                              </b-form-group>
                            </b-col>

                          </b-row>
                          <b-row>
                            <b-col
                              cols="12"
                            >
                              <line-chat-message
                                :data="chtmsg.jsonMessage"
                                @action="onAction"
                              />
                              <div
                                v-if="chtmsg.chatmessage_type === 'flex' && chtmsg.actionData !== null"
                                class="mt-1"
                              >
                                <b-form-group
                                  :label="$t('Type')"
                                >
                                  <b-form-input
                                    v-model="chtmsg.actionData.type"
                                  />
                                </b-form-group>
                                <b-form-group
                                  :label="$t('Label')"
                                >
                                  <b-form-input
                                    v-model="chtmsg.actionData.label"
                                  />
                                </b-form-group>
                                <b-form-group
                                  v-if="chtmsg.actionData.type === 'uri'"
                                  :label="$t('URI')"
                                >
                                  <b-form-input
                                    v-model="chtmsg.actionData.uri"
                                  />
                                </b-form-group>
                                <b-form-group
                                  v-if="chtmsg.actionData.type === 'message' || chtmsg.actionData.type === 'postback'"
                                  :label="$t('Text')"
                                >
                                  <b-form-input
                                    v-model="chtmsg.actionData.text"
                                  />
                                </b-form-group>

                                <b-form-group
                                  v-if="chtmsg.actionData.type === 'postback' || chtmsg.actionData.type === 'datetimepicker'"
                                  :label="$t('Data')"
                                >
                                  <b-form-input
                                    v-model="chtmsg.actionData.data"
                                  />
                                </b-form-group>
                                <b-form-group
                                  v-if="chtmsg.actionData.type === 'datetimepicker'"
                                  :label="$t('Mode')"
                                >
                                  <b-form-input
                                    v-model="chtmsg.actionData.mode"
                                  />
                                </b-form-group>
                                <b-form-group
                                  v-if="chtmsg.actionData.type === 'datetimepicker'"
                                  :label="$t('Initial')"
                                >
                                  <b-form-input
                                    v-model="chtmsg.actionData.initial"
                                  />
                                </b-form-group>
                                <b-form-group
                                  v-if="chtmsg.actionData.type === 'datetimepicker'"
                                  :label="$t('Max')"
                                >
                                  <b-form-input
                                    v-model="chtmsg.actionData.max"
                                  />
                                </b-form-group>
                                <b-form-group
                                  v-if="chtmsg.actionData.type === 'datetimepicker'"
                                  :label="$t('Min')"
                                >
                                  <b-form-input
                                    v-model="chtmsg.actionData.min"
                                  />
                                </b-form-group>
                              </div>
                              <div
                                v-if="chtmsg.chatmessage_type === 'imagemap' && chtmsg.actionData !== null"
                                class="mt-1"
                              >
                                <div>
                                  <b-tabs>
                                    <b-tab
                                      v-for="(action, actionIndex) in chtmsg.actionData"
                                      :key="actionIndex"
                                      :title="`${$t('Area')} ${actionIndex+1}`"
                                    >
                                      <div
                                        style="border-radius: 0.8rem; border: 1px solid #eeeeee; padding: 12px 12px 12px 12px; margin-bottom: 0.5rem;"
                                      >
                                        <b-form-group
                                          v-if="action.area"
                                          :label="$t('Area')"
                                        >
                                          <div
                                            style="border: 1px solid #eeeeee; padding: 12px 12px 12px 12px; margin-bottom: 0.5rem;"
                                          >
                                            <b-row>
                                              <b-col
                                                v-for="(value, key) in action.area"
                                                :key="key"
                                              >
                                                <span>{{ key }}</span>
                                                <b-form-input
                                                  :value="value"
                                                  style="margin-top: 5px;"
                                                />
                                              </b-col>
                                            </b-row>
                                          </div>
                                        </b-form-group>
                                        <b-form-group
                                          v-if="action.type"
                                          :label="$t('Type')"
                                        >
                                          <b-form-input
                                            v-model="action.type"
                                          />
                                        </b-form-group>
                                        <b-form-group
                                          v-if="action.text && action.type === 'message'"
                                          :label="$t('Text')"
                                        >
                                          <b-form-input
                                            v-model="action.text"
                                          />
                                        </b-form-group>
                                        <b-form-group
                                          v-if="action.linkUri && action.type === 'uri'"
                                          :label="$t('Link Uri')"
                                        >
                                          <b-form-input
                                            v-model="action.linkUri"
                                          />
                                        </b-form-group>
                                      </div>
                                    </b-tab>
                                  </b-tabs>
                                </div>
                              </div>
                            </b-col>
                          </b-row>
                        </b-tab>
                      </b-tabs>
                    </div>

                    <!-- <b-row>
                      <b-col
                        cols="12"
                      >

                        <b-form-group
                          v-if="(typeMulticastForm === 'Add' || typeMulticastForm === 'Edit')"
                          :label="`${$t('Chat Message')} 1`"
                          label-for="multicast-chatmessage"
                        >
                          <validation-provider
                            #default="validationContext"
                            :name="`${$t('Chat Message')} 1`"
                            rules="required"
                          >
                            <b-form-input
                              v-model="multicastData.chatmessage_id"
                              style="display: none;"
                              :state="getValidationState(validationContext)"
                            />
                            <b-input-group class="m-0">
                              <b-input-group-prepend
                                is-text
                                style="cursor: pointer;"
                                @click="selectChatmessage(1)"
                              >
                                <feather-icon
                                  icon="PlusIcon"
                                  size="12"
                                />
                              </b-input-group-prepend>
                              <b-form-input
                                id="multicast-chatmessage"
                                ref="refChatmessage"
                                v-model="multicastData.chatmessage_name"
                                :disabled="true"
                                style="background: #f8f8f8 !important;"
                              />
                            </b-input-group>
                            <small class="text-danger">{{ validationContext.errors[0] }}</small>
                          </validation-provider>

                        </b-form-group>

                        <b-form-group
                          v-else
                          :label="`${$t('Chat Message')} 1`"
                          label-for="multicast-chatmessage"
                        >
                          <span class="form-info-box">{{ multicastData.chatmessage_name }}</span>
                        </b-form-group>
                      </b-col>

                    </b-row>
                    <b-row>
                      <b-col cols="12">
                        <line-chat-message
                          :data="jsonMessage"
                          @action="onAction"
                        />
                        <div
                          v-if="multicastData.chatmessage_type === 'flex' && actionData !== null"
                          class="mt-1"
                        >
                          <b-form-group
                            :label="$t('Type')"
                          >
                            <b-form-input
                              v-model="actionData.type"
                            />
                          </b-form-group>
                          <b-form-group
                            :label="$t('Label')"
                          >
                            <b-form-input
                              v-model="actionData.label"
                            />
                          </b-form-group>
                          <b-form-group
                            v-if="actionData.type === 'uri'"
                            :label="$t('URI')"
                          >
                            <b-form-input
                              v-model="actionData.uri"
                            />
                          </b-form-group>
                          <b-form-group
                            v-if="actionData.type === 'message' || actionData.type === 'postback'"
                            :label="$t('Text')"
                          >
                            <b-form-input
                              v-model="actionData.text"
                            />
                          </b-form-group>

                          <b-form-group
                            v-if="actionData.type === 'postback' || actionData.type === 'datetimepicker'"
                            :label="$t('Data')"
                          >
                            <b-form-input
                              v-model="actionData.data"
                            />
                          </b-form-group>
                          <b-form-group
                            v-if="actionData.type === 'datetimepicker'"
                            :label="$t('Mode')"
                          >
                            <b-form-input
                              v-model="actionData.mode"
                            />
                          </b-form-group>
                          <b-form-group
                            v-if="actionData.type === 'datetimepicker'"
                            :label="$t('Initial')"
                          >
                            <b-form-input
                              v-model="actionData.initial"
                            />
                          </b-form-group>
                          <b-form-group
                            v-if="actionData.type === 'datetimepicker'"
                            :label="$t('Max')"
                          >
                            <b-form-input
                              v-model="actionData.max"
                            />
                          </b-form-group>
                          <b-form-group
                            v-if="actionData.type === 'datetimepicker'"
                            :label="$t('Min')"
                          >
                            <b-form-input
                              v-model="actionData.min"
                            />
                          </b-form-group>
                        </div>
                        <div
                          v-if="multicastData.chatmessage_type === 'imagemap' && actionData !== null"
                          class="mt-1"
                        >
                          <div>
                            <b-tabs>
                              <b-tab
                                v-for="(action, index) in actionData"
                                :key="index"
                                :title="`${$t('Area')} ${index+1}`"
                              >
                                <div
                                  style="border-radius: 0.8rem; border: 1px solid #eeeeee; padding: 12px 12px 12px 12px; margin-bottom: 0.5rem;"
                                >
                                  <b-form-group
                                    v-if="action.area"
                                    :label="$t('Area')"
                                  >
                                    <div
                                      style="border: 1px solid #eeeeee; padding: 12px 12px 12px 12px; margin-bottom: 0.5rem;"
                                    >
                                      <b-row>
                                        <b-col
                                          v-for="(value, key) in action.area"
                                          :key="key"
                                        >
                                          <span>{{ key }}</span>
                                          <b-form-input
                                            :value="value"
                                            style="margin-top: 5px;"
                                          />
                                        </b-col>
                                      </b-row>
                                    </div>
                                  </b-form-group>
                                  <b-form-group
                                    v-if="action.type"
                                    :label="$t('Type')"
                                  >
                                    <b-form-input
                                      v-model="action.type"
                                    />
                                  </b-form-group>
                                  <b-form-group
                                    v-if="action.text && action.type === 'message'"
                                    :label="$t('Text')"
                                  >
                                    <b-form-input
                                      v-model="action.text"
                                    />
                                  </b-form-group>
                                  <b-form-group
                                    v-if="action.linkUri && action.type === 'uri'"
                                    :label="$t('Link Uri')"
                                  >
                                    <b-form-input
                                      v-model="action.linkUri"
                                    />
                                  </b-form-group>
                                </div>
                              </b-tab>
                            </b-tabs>
                          </div>
                        </div>
                      </b-col>
                    </b-row> -->

                  </b-col>
                </b-row>

              </div>
            </b-card>
          </div>

        </b-form>

      </div>

    </validation-observer>

    <!-- Chatmessage Select -->
    <chatmessage-select
      v-model="showChatmessageSelectModal"
      :title="`${$t('Select')} ${$t('Chatmessage')}`"
      :chatmessage-data="chatmessageData"
      @update-chatmessage-select="updateChatmessageSelect"
      @discard-chatmessage-select="discardChatmessageSelect"
    />

    <!-- Audience Select -->
    <audience-select
      v-model="showAudienceSelectModal"
      :title="`${$t('Select')} ${$t('Audience')}`"
      :audience-data="audienceData"
      @update-audience-select="updateAudienceSelect"
      @discard-audience-select="discardAudienceSelect"
    />

    <b-overlay
      :show="busy"
      no-wrap
      @shown="onShown"
      @hidden="onHidden"
    >
      <template #overlay>
        <div
          v-if="processing"
          class="text-center p-2 bg-primary text-light rounded"
        >
          <feather-icon
            icon="UploadCloudIcon"
            size="20"
          />
          <div class="mb-2">
            {{ `${$t('Processing')}...` }}
          </div>
          <b-progress
            min="1"
            max="10"
            :value="counter"
            variant="success"
            height="6px"
            class="mx-n1"
          />
        </div>
        <div
          v-else
          ref="dialog"
          tabindex="-1"
          role="dialog"
          aria-modal="false"
          aria-labelledby="form-confirm-label"
          class="text-center p-3"
        >
          <b-card-text
            class="font-weight-bolder"
          >
            {{ $t('Are you sure?') }}
          </b-card-text>
          <div class="d-flex">
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-danger"
              class="mr-3"
              @click="onCancel"
            >
              {{ $t('Cancel') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              variant="outline-success"
              @click="onOK"
            >
              {{ $t('Ok') }}
            </b-button>
          </div>
        </div>
      </template>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BButton, BForm,
  BFormGroup, BFormInput,
  BRow,
  BCol,
  BCard,
  BOverlay,
  BProgress,
  BCardText,
  BTab,
  BTabs,
  BFormRadio,
  BInputGroup,
  BInputGroupPrepend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required } from '@validations'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import LineChatMessage from '@core/components/line-chat-message/LineChatMessage.vue'
import ChatmessageSelect from '@/views/lineapi/chatmessage/ChatmessageSelect.vue'
import flatPickr from 'vue-flatpickr-component'
import AudienceSelect from '@/views/lineapi/audience/AudienceSelect.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    LineChatMessage,
    ChatmessageSelect,
    AudienceSelect,
    flatPickr,

    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BOverlay,
    BRow,
    BCol,
    BCard,
    BProgress,
    BCardText,
    BTab,
    BTabs,
    BFormRadio,
    BInputGroup,
    BInputGroupPrepend,

    ValidationProvider,
    ValidationObserver,
    FeatherIcon,
  },
  model: {
    prop: 'shallShowMulticastFormModal',
    event: 'update:shall-show-multicast-form-modal',
  },
  props: {
    shallShowMulticastFormModal: {
      type: Boolean,
      required: true,
    },
    typeMulticastForm: {
      type: String,
      default: 'Add',
    },
    multicastData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      required,
      busy: false,
      processing: false,
      counter: 1,
      interval: null,
      skin: store.state.appConfig.layout.skin,
      jsonMessage: {},
      actionData: null,
      showChatmessageSelectModal: false,
      chatmessageData: {},
      chatmessageArray: [
        {
          chatmessage_id: '',
          chatmessage_name: '',
          chatmessage_type: '',
          chatmessage_message: '',
          actionData: null,
          jsonMessage: {},
          chatmessage_no: 0,
        },
        {
          chatmessage_id: '',
          chatmessage_name: '',
          chatmessage_type: '',
          chatmessage_message: '',
          actionData: null,
          jsonMessage: {},
          chatmessage_no: 0,
        },
        {
          chatmessage_id: '',
          chatmessage_name: '',
          chatmessage_type: '',
          chatmessage_message: '',
          actionData: null,
          jsonMessage: {},
          chatmessage_no: 0,
        },
        {
          chatmessage_id: '',
          chatmessage_name: '',
          chatmessage_type: '',
          actionData: null,
          jsonMessage: {},
          chatmessage_no: 0,
        },
        {
          chatmessage_id: '',
          chatmessage_name: '',
          chatmessage_type: '',
          chatmessage_message: '',
          actionData: null,
          jsonMessage: {},
          chatmessage_no: 0,
        },
      ],
      chatmessageIndex: 0,
      showAudienceSelectModal: false,
      audienceData: {},
    }
  },
  computed: {
    lineOa() {
      const value = store.state.lineapi.lineOa
      if (value === '' || value === undefined) {
        return ''
      }
      return JSON.parse(value)
    },
    lineOaId() {
      const value = this.lineOa.id
      if (value === '' || value === undefined) {
        return ''
      }
      return value
    },
  },
  watch: {
    multicastData() {
      for (let i = 0; i < this.chatmessageArray.length; i += 1) {
        this.chatmessageArray[i].jsonMessage = {}
        this.chatmessageArray[i].actionData = null
        this.chatmessageArray[i].chatmessage_id = ''
        this.chatmessageArray[i].chatmessage_name = ''
        this.chatmessageArray[i].chatmessage_type = ''
        this.chatmessageArray[i].chatmessage_message = ''
        this.chatmessageArray[i].chatmessage_no = 0
      }
      if (this.multicastData?.multicast_chatmessage) {
        const multicastChatmessage = this.multicastData?.multicast_chatmessage
        for (let i = 0; i < multicastChatmessage.length; i += 1) {
          this.chatmessageArray[i].chatmessage_id = multicastChatmessage[i].chatmessage_id
          this.chatmessageArray[i].chatmessage_name = multicastChatmessage[i].chatmessage_name
          this.chatmessageArray[i].chatmessage_type = multicastChatmessage[i].chatmessage_type
          this.chatmessageArray[i].chatmessage_message = multicastChatmessage[i].chatmessage_message
          this.chatmessageArray[i].chatmessage_no = i + 1

          if (multicastChatmessage[i].chatmessage_message !== '' && multicastChatmessage[i].chatmessage_message !== null) {
            this.chatmessageArray[i].actionData = null
            if (multicastChatmessage[i].chatmessage_type === 'flex') {
              if (this.chatmessageArray[i].chatmessage_message.contents !== '') {
                this.chatmessageArray[i].jsonMessage = multicastChatmessage[i].chatmessage_message
              } else {
                this.chatmessageArray[i].jsonMessage = {}
              }
            } else {
              this.chatmessageArray[i].jsonMessage = multicastChatmessage[i].chatmessage_message
            }
            if (multicastChatmessage[i].chatmessage_type === 'imagemap') {
              const { actions } = multicastChatmessage[i].chatmessage_message
              this.chatmessageArray[i].actionData = actions
            }
          } else {
            this.chatmessageArray[i].jsonMessage = {}
          }
        }
      }
      // console.log(this.chatmessageArray)
      //   this.jsonMessage = {}
      //   this.actionData = null
      //   this.chatmessageData = {}
      //   if (this.multicastData?.chatmessage_id
      //     && this.multicastData.chatmessage_id !== '') {
      //     this.chatmessageData.id = this.multicastData.chatmessage_id
      //     this.chatmessageData.name = this.multicastData.chatmessage_name
      //     this.chatmessageData.message = this.multicastData.chatmessage_message
      //     this.chatmessageData.type = this.multicastData.chatmessage_type

      //   if (this.chatmessageData.message !== '' && this.chatmessageData.message !== null) {
      //     this.actionData = null
      //     if (this.chatmessageData.type === 'flex') {
      //       if (this.chatmessageData.message.contents !== '') {
      //         this.jsonMessage = this.chatmessageData.message
      //       } else {
      //         this.jsonMessage = {}
      //       }
      //     } else {
      //       this.jsonMessage = this.chatmessageData.message
      //     }
      //     if (this.chatmessageData.type === 'imagemap') {
      //       const { actions } = this.chatmessageData.message
      //       this.actionData = actions
      //     }
      //   } else {
      //     this.jsonMessage = {}
      //   }
      // } else {
      //   this.multicastData.chatmessage_id = ''
      //   this.multicastData.chatmessage_name = ''
      //   this.multicastData.chatmessage_type = ''
      //   this.multicastData.chatmessage_message = ''
      // }
    },
  },
  methods: {
    textFirstUpper(text) {
      return text.slice(0, 1).toUpperCase() + text.slice(1, text.length)
    },
    stateVariant(state) {
      if (state === 'waiting') return 'warning'
      if (state === 'schedule') return 'info'
      if (state === 'success') return 'success'
      return 'secondary'
    },
    onShown() {
      // Focus the dialog prompt
      this.$refs.dialog.focus()
    },
    onHidden() {
      // In this case, we return focus to the submit button
      // You may need to alter this based on your application requirements
      this.$refs.submit.focus()
    },
    onSubmit() {
      this.$refs.refFormObserver.validate().then(success => {
        if (success) {
          this.processing = false
          this.busy = true
        }
      })
    },
    onCancel() {
      this.busy = false
    },
    onOK() {
      this.processing = true
      if (this.typeMulticastForm === 'Add') {
        const scheduleDatetime = `${this.multicastData.schedule_date} ${this.multicastData.schedule_time}`
        const chatMessages = []
        for (let i = 0; i < this.chatmessageArray.length; i += 1) {
          if (this.chatmessageArray[i].chatmessage_id !== '') {
            chatMessages[i] = {
              chatmessage_id: this.chatmessageArray[i].chatmessage_id,
              chatmessage_no: this.chatmessageArray[i].chatmessage_no,
            }
          }
        }
        const postData = {
          line_oa_id: this.lineOaId,
          name: this.multicastData.name,
          audience_id: this.multicastData.audience_id,
          chatmessage_id: this.multicastData.chatmessage_id,
          chatmessages: chatMessages,
          sent: 0,
          reached: 0,
          is_schedule: this.multicastData.is_schedule,
          schedule_datetime: this.$moment(scheduleDatetime).format('YYYY-MM-DD HH:mm:ss'),
        }
        // console.log(postData)
        store.dispatch('multicast-store/addMulticast', postData)
          .then(() => {
            const okMsg = this.$i18n.t('Process on successfully')
            this.$toast({
              component: ToastificationContent,
              props: {
                title: okMsg,
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            },
            {
              position: 'bottom-right',
            })
            this.busy = false
            this.processing = false

            this.$emit('update-multicast-form')
            this.$emit('update:shall-show-multicast-form-modal', false)
          })
          .catch(error => {
            const { response, message } = error
            if (response) {
              const errorMsg = this.$i18n.t(response.data.error)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: errorMsg,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            } else if (message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error.message,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            }
            this.busy = false
            this.processing = false
          })
      } else if (this.typeMulticastForm === 'Edit') {
        const scheduleDatetime = `${this.multicastData.schedule_date} ${this.multicastData.schedule_time}`
        const chatMessages = []
        for (let i = 0; i < this.chatmessageArray.length; i += 1) {
          if (this.chatmessageArray[i].chatmessage_id !== '') {
            chatMessages[i] = {
              chatmessage_id: this.chatmessageArray[i].chatmessage_id,
              chatmessage_no: this.chatmessageArray[i].chatmessage_no,
            }
          }
        }
        const postData = {
          name: this.multicastData.name,
          audience_id: this.multicastData.audience_id,
          chatmessage_id: this.multicastData.chatmessage_id,
          chatmessages: chatMessages,
          is_schedule: this.multicastData.is_schedule,
          schedule_datetime: this.$moment(scheduleDatetime).format('YYYY-MM-DD HH:mm:ss'),
        }
        store.dispatch('multicast-store/editMulticast', { id: this.multicastData.id, data: postData })
          .then(() => {
            const okMsg = this.$i18n.t('Process on successfully')
            this.$toast({
              component: ToastificationContent,
              props: {
                title: okMsg,
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            },
            {
              position: 'bottom-right',
            })
            this.busy = false
            this.processing = false

            this.$emit('update-multicast-form')
            this.$emit('update:shall-show-multicast-form-modal', false)
          })
          .catch(error => {
            const { response, message } = error
            if (response) {
              const errorMsg = this.$i18n.t(response.data.error)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: errorMsg,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            } else if (message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error.message,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            }
            this.busy = false
            this.processing = false
          })
      } else if (this.typeMulticastForm === 'Delete') {
        store.dispatch('multicast-store/deleteMulticast', {
          id: this.multicastData.id,
        })
          .then(() => {
            const okMsg = this.$i18n.t('Process on successfully')
            this.$toast({
              component: ToastificationContent,
              props: {
                title: okMsg,
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            },
            {
              position: 'bottom-right',
            })
            this.busy = false
            this.processing = false

            this.$emit('update-multicast-form')
            this.$emit('update:shall-show-multicast-form-modal', false)
          })
          .catch(error => {
            const { response, message } = error
            if (response) {
              const errorMsg = this.$i18n.t(response.data.error)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: errorMsg,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            } else if (message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error.message,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            }
            this.busy = false
            this.processing = false
          })
      }
    },
    onDiscard() {
      this.$emit('discard-multicast-form')
      this.$emit('update:shall-show-multicast-form-modal', false)
    },
    onErrorSourceJson() {
      // console.log('error')
    },
    onSelectChatMessageType(type) {
      this.multicastData.type = type
    },
    onAction(action) {
      this.chatmessageArray[this.chatmessageIndex].actionData = action
    },
    updateChatmessageSelect(data) {
      this.$nextTick(() => {
        this.chatmessageArray[this.chatmessageIndex].chatmessage_id = data.select.id
        this.chatmessageArray[this.chatmessageIndex].chatmessage_name = data.select.name
        this.chatmessageArray[this.chatmessageIndex].chatmessage_message = data.select.message
        this.chatmessageArray[this.chatmessageIndex].chatmessage_type = data.select.type
        this.chatmessageArray[this.chatmessageIndex].chatmessage_no = this.chatmessageIndex + 1
        this.chatmessageData.id = data.select.id
        this.chatmessageData.name = data.select.name
        this.chatmessageData.message = data.select.message
        this.chatmessageData.type = data.select.type
        if (this.chatmessageData.message !== '' && this.chatmessageData.message !== null) {
          this.chatmessageArray[this.chatmessageIndex].actionData = null
          if (this.chatmessageData.type === 'flex') {
            if (this.chatmessageData.message.contents !== '') {
              this.chatmessageArray[this.chatmessageIndex].jsonMessage = this.chatmessageData.message
            } else {
              this.chatmessageArray[this.chatmessageIndex].jsonMessage = {}
            }
          } else {
            this.chatmessageArray[this.chatmessageIndex].jsonMessage = this.chatmessageData.message
          }
          if (this.chatmessageData.type === 'imagemap') {
            const { actions } = this.chatmessageData.message
            this.chatmessageArray[this.chatmessageIndex].actionData = actions
          }
        } else {
          this.chatmessageArray[this.chatmessageIndex].jsonMessage = {}
        }

        this.$refs.refForm.focus()
      })

      // this.$nextTick(() => {
      //   this.multicastData.chatmessage_id = data.select.id
      //   this.multicastData.chatmessage_name = data.select.name
      //   this.multicastData.chatmessage_message = data.select.message
      //   this.multicastData.chatmessage_type = data.select.type
      //   this.chatmessageData.id = this.multicastData.chatmessage_id
      //   this.chatmessageData.name = this.multicastData.chatmessage_name
      //   this.chatmessageData.message = this.multicastData.chatmessage_message
      //   this.chatmessageData.type = this.multicastData.chatmessage_type
      //   if (this.chatmessageData.message !== '' && this.chatmessageData.message !== null) {
      //     this.actionData = null
      //     if (this.chatmessageData.type === 'flex') {
      //       if (this.chatmessageData.message.contents !== '') {
      //         this.jsonMessage = this.chatmessageData.message
      //       } else {
      //         this.jsonMessage = {}
      //       }
      //     } else {
      //       this.jsonMessage = this.chatmessageData.message
      //     }
      //     if (this.chatmessageData.type === 'imagemap') {
      //       const { actions } = this.chatmessageData.message
      //       this.actionData = actions
      //     }
      //   } else {
      //     this.jsonMessage = {}
      //   }

      //   this.$refs.refForm.focus()
      // })
    },
    discardChatmessageSelect() {
    },
    selectChatmessage(index) {
      this.chatmessageIndex = index
      // console.log(this.chatmessageIndex)

      if (this.chatmessageArray[index].chatmessage_id === '') {
        this.chatmessageData = {}
      } else {
        this.chatmessageData = {}
        this.chatmessageData.id = this.chatmessageArray[index].chatmessage_id
        this.chatmessageData.name = this.chatmessageArray[index].chatmessage_name
        this.chatmessageData.message = this.chatmessageArray[index].chatmessage_message
        this.chatmessageData.type = this.chatmessageArray[index].chatmessage_type
      }

      // if (this.multicastData.chatmessage_id === '') {
      //   this.chatmessageData = {}
      // } else {
      //   this.chatmessageData = {}
      //   this.chatmessageData.id = this.multicastData.chatmessage_id
      //   this.chatmessageData.name = this.multicastData.chatmessage_name
      //   this.chatmessageData.message = this.multicastData.chatmessage_message
      //   this.chatmessageData.type = this.multicastData.chatmessage_type
      // }
      this.showChatmessageSelectModal = true
    },
    clearSelectChatmessage(index) {
      this.chatmessageData = {}
      this.chatmessageArray[index].chatmessage_id = ''
      this.chatmessageArray[index].chatmessage_name = ''
      this.chatmessageArray[index].chatmessage_message = ''
      this.chatmessageArray[index].chatmessage_type = ''
      this.chatmessageArray[index].jsonMessage = {}
    },
    selectAudience() {
      if (this.multicastData.audience_id === '') {
        this.audienceData = {}
      } else {
        this.audienceData = {}
        this.audienceData.id = this.multicastData.audience_id
        this.audienceData.name = this.multicastData.audience_name
      }
      this.showAudienceSelectModal = true
    },
    updateAudienceSelect(data) {
      this.$nextTick(() => {
        this.multicastData.audience_id = data.select.id
        this.multicastData.audience_name = data.select.name
        this.audienceData.id = this.multicastData.audience_id
        this.audienceData.name = this.multicastData.audience_name

        this.$refs.refForm.focus()
      })
    },
    discardAudienceSelect() {
    },
  },
  setup() {
    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation()

    return {
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/_variables';
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.modal{
  .line-height-info{
    padding-bottom: 0.6rem;
    &.end{
      padding-bottom: 0;
    }
  }
  .modal-header,
  .modal-header[class*='bg-'] {
    background-color: #fff !important;
  }
  .modal-body {
    background-color: $body-bg !important;
  }
}
.form-info-box{
  display: flex;
  padding: 0.6rem 1rem;
  background-color: #f8f8f8;
  background-clip: padding-box;
  border: 1px solid #f0f0f0;
  border-radius: 0.357rem;
}

.dark-layout {
  .modal{
    .line-height-info{
      padding-bottom: 0.6rem;
      &.end{
        padding-bottom: 0;
      }
    }
    .modal-header,
    .modal-header[class*='bg-'] {
      background-color: $theme-dark-body-bg !important;
    }
    .modal-body {
      background-color: $theme-dark-table-active-bg !important;
    }
  }
  .form-info-box{
    display: flex;
    padding: 0.6rem 1rem;
    background-color: $theme-dark-input-bg;
    background-clip: padding-box;
    border: 1px solid $theme-dark-border-color;
    border-radius: 0.357rem;
  }
}
.jsoneditor-poweredBy {
  display: none !important;
}
.jsoneditor {
  border: thin solid #d8d6de !important;
}
.jsoneditor-menu{
  background-color: #aaaaaa !important;
  border-bottom: 1px solid #aaaaaa !important;
}
</style>
